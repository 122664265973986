import * as Sentry from '@sentry/nuxt'

export function useApi() {
  const { public: { BASE_API_URL } } = useRuntimeConfig()
  const API_URL = useCookie('api').value || BASE_API_URL;
  const log = useLogger('useApi')

  const handleError = (err: Error) => {
    log.danger(err)
    if (!err.name.startsWith("ERR_NETWORK")) {
      Sentry.captureException(err)
    }
    throw err
  }

  const buildQueryString = (params?: Record<string, any>): string =>
    params
      ? '?' +
        Object.entries(params)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
          .join('&')
      : '';

  async function fetchData<T>(
    url: string,
    method: string,
    params?: object,
    body?: any
  ): Promise<T> {
    try {
      const fullUrl = url + buildQueryString(params)
      if (import.meta.dev && import.meta.client) {
        log.info(fullUrl)
      }
      return await $fetch<T>(fullUrl, {
        method,
        baseURL: API_URL,
        body,
      })
    } catch (err: unknown) {
      handleError(err as Error)
      throw err
    }
  }

  return {
    get<T>(url: string, params?: object): Promise<T> {
      return fetchData<T>(url, 'GET', params)
    },
    post<T>(url: string, body?: object, params?: object): Promise<T> {
      return fetchData<T>(url, 'POST', params, body)
    },
    put<T>(url: string, body?: object): Promise<T> {
      return fetchData<T>(url, 'PUT', undefined, body)
    },
    delete<T>(url: string, params?: object): Promise<T> {
      return fetchData<T>(url, 'DELETE', params)
    },
  }
}
